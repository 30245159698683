export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const CHECK_RESET_PASSWORD = 'CHECK_RESET_PASSWORD';
export const RESET_PASSWORD_LINK = 'RESET_PASSWORD_LINK';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const GET_START_DATA = 'GET_START_DATA';

export const GET_STATES = 'GET_STATES';
export const SET_STATES = 'SET_STATES';
export const SET_DOCTYPES = 'SET_DOCTYPES';
export const SET_STATUSES = 'SET_STATUSES';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const COPY_TEMPLATE = 'COPY_TEMPLATE';
export const DELETE_TEMPLATES = 'DELETE_TEMPLATES';
export const GET_TEMPLATE_DETAILS = 'GET_TEMPLATE_DETAILS';
export const SET_TEMPLATE_DETAILS = 'SET_TEMPLATE_DETAILS';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const CREATE_TEMPLATE_AND_SECTION = 'CREATE_TEMPLATE_AND_SECTION';

// Auto refresh
export const AUTOREFRESH_PROCESS = 'AUTOREFRESH_PROCESS';
export const UPDATE_DOCUMENTS_COUNT = 'UPDATE_DOCUMENTS_COUNT';

// User Notifications
export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';

// Initial data
export const GET_RESOURCES = 'GET_RESOURCES';
export const SET_RESOURCES = 'SET_RESOURCES';
export const GET_METADATA_RESOURCES = 'GET_METADATA_RESOURCES';
export const SET_METADATA_RESOURCES = 'SET_METADATA_RESOURCES';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const GET_DOCUMENTS_COUNT = 'GET_DOCUMENTS_COUNT';
export const GET_DOCUMENT_DETAILS = 'GET_DOCUMENT_DETAILS';
export const SET_DOCUMENT_DETAILS = 'SET_DOCUMENT_DETAILS';
export const EDIT_DOCUMENT_DETAILS = 'EDIT_DOCUMENT_DETAILS';
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const GET_USER_PII_DATA = 'GET_USER_PII_DATA';

export const GET_SECTIONS = 'GET_SECTIONS';
export const SET_SECTIONS = 'SET_SECTIONS';
export const DELETE_SECTIONS = 'DELETE_SECTIONS';
export const ADD_SECTION = 'ADD_SECTION';
export const CREATE_SECTION = 'CREATE_SECTION';
export const EDIT_SECTIONS = 'EDIT_SECTIONS';
export const GET_SECTION_DETAILS = 'GET_SECTION_DETAILS';
export const SET_SECTION_DETAILS = 'SET_SECTION_DETAILS';
export const EDIT_SECTION = 'EDIT_SECTION';

export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADED = 'SET_LOADED';
export const SET_LAST_CREATED_SECTION = 'SET_LAST_CREATED_SECTION';

export const GET_USER_PROFILE_DATA = 'GET_USER_PROFILE_DATA';
export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';
export const UPDATE_USER_PROFILE_DATA = 'UPDATE_USER_PROFILE_DATA';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const GET_USER_COMPANY_DATA = 'GET_USER_COMPANY_DATA';
export const SET_USER_COMPANY_DATA = 'SET_USER_COMPANY_DATA';
export const UPDATE_USER_COMPANY_DATA = 'UPDATE_USER_COMPANY_DATA';

// Default user's signature
export const GET_DEFAULT_SIGNATURE_DATA = 'GET_DEFAULT_SIGNATURE_DATA';
export const SET_DEFAULT_SIGNATURE_DATA = 'SET_DEFAULT_SIGNATURE_DATA';
export const DELETE_DEFAULT_SIGNATURE_DATA = 'DELETE_DEFAULT_SIGNATURE_DATA';

// Signing certificates
export const GET_SIGNING_CERTIFICATES = 'GET_SIGNING_CERTIFICATES';
export const SET_USER_SIGNING_CERTIFICATE = 'SET_USER_SIGNING_CERTIFICATE';
export const RESET_USER_PII_DATA = 'RESET_USER_PII_DATA';
export const UPDATE_USER_SIGNING_CERTIFICATE = 'UPDATE_USER_SIGNING_CERTIFICATE';

// Referral codes
export const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE';
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';
export const CHANGE_REFERRAL_CODE = 'CHANGE_REFERRAL_CODE';

// User personal information data and settings (PII)
export const SET_USER_PERSONAL_DATA = 'SET_USER_PERSONAL_DATA';
export const UPDATE_USER_PERSONAL_DATA = 'UPDATE_USER_PERSONAL_DATA';
export const UPDATE_USER_PERSONAL_SIGNATURE = 'UPDATE_USER_PERSONAL_SIGNATURE';
export const SET_USER_PERSONAL_DATA_PROPERTY = 'SET_USER_PERSONAL_DATA_PROPERTY';
export const SET_USER_PII_DATA = 'SET_USER_PII_DATA';

// Users
export const GET_USER_LIST_DATA = 'GET_USER_LIST_DATA';
export const SET_USER_LIST_DATA = 'SET_USER_LIST_DATA';
export const SET_USER_LIST_DATA_LOADER = 'SET_USER_LIST_DATA_LOADER';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const DELETE_USER = 'DELETE_USER';
export const RESEND_INVITE_TO_USER = 'RESEND_INVITE_TO_USER';

// GROUPS
export const GET_GROUPS = 'GET_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUPS_BATCH = 'DELETE_GROUPS_BATCH';
export const GET_GROUP = 'GET_GROUP';
export const SET_GROUP = 'SET_GROUP';
export const UPDATE_GROUP_MEMBER = 'UPDATE_GROUP_MEMBER';

// Public page
export const GET_PUBLIC_PAGE_DATA = 'GET_PUBLIC_PAGE_DATA';
export const SET_PUBLIC_PAGE_DATA = 'SET_PUBLIC_PAGE_DATA';
export const UPDATE_PUBLIC_PAGE_DATA = 'UPDATE_PUBLIC_PAGE_DATA';
export const UPDATE_PUBLIC_PAGE_DATA_SAGA = 'UPDATE_PUBLIC_PAGE_DATA_SAGA';
export const SET_PUBLIC_PAGE_STRUCTURE = 'SET_PUBLIC_PAGE_STRUCTURE';
export const SET_PUBLIC_PAGE_CURRENT_DOCUMENT = 'SET_PUBLIC_PAGE_CURRENT_DOCUMENT';
export const UPDATE_PUBLIC_PAGE_DOCUMENTS = 'UPDATE_PUBLIC_PAGE_DOCUMENTS';
export const UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA = 'UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA';
export const SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX = 'SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX';
export const SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES = 'SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES';
export const SET_CURRENT_DOCUMENT_TAB = 'SET_CURRENT_DOCUMENT_TAB';
export const ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE = 'ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE';
export const DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE = 'DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE';
export const SET_PUBLIC_PAGE_SENT_FILE_COUNTER = 'SET_PUBLIC_PAGE_SENT_FILE_COUNTER';
export const UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD = 'UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD';
export const UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD = 'UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD';
export const DELETE_CURRENT_ID_FROM_DATA = 'DELETE_CURRENT_ID_FROM_DATA';
export const SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE = 'SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE';
export const CHECK_REMEMBERED_PII_DATA_IN_DATABASE = 'CHECK_REMEMBERED_PII_DATA_IN_DATABASE';
export const SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW = 'SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW';
export const SEND_EMAIL_IN_PII_RECALL_FLOW = 'SEND_EMAIL_IN_PII_RECALL_FLOW';
export const UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA = 'UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA';
export const UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES = 'UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES';
export const SET_HISTORY_LOG_CURRENT_DOCUMENT = 'SET_HISTORY_LOG_CURRENT_DOCUMENT';
export const DELETE_PART_OF_HISTORY_LOG = 'DELETE_PART_OF_HISTORY_LOG';
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';
export const SET_ATTACHMENTS_QUEUE_COMPLETED = 'SET_ATTACHMENTS_QUEUE_COMPLETED';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const SET_SEND_LINK_FLOW = 'SET_SEND_LINK_FLOW';
export const SET_DISMISS_INTRODUCE_STEP = 'SET_DISMISS_INTRODUCE_STEP';
export const SET_PUBLIC_PAGE_START_LINK = 'SET_PUBLIC_PAGE_START_LINK';
export const SET_AUTOFILL_PII_DATA = 'SET_AUTOFILL_PII_DATA';
export const SET_PII_DATA_MODAL_WAS_VISIBLE = 'SET_PII_DATA_MODAL_WAS_VISIBLE';
export const SET_MONDAY_COM_BOARD = 'SET_MONDAY_COM_BOARD';
export const SET_GROUPED_FIELDS_STRUCTURE = 'SET_GROUPED_FIELDS_STRUCTURE';
export const UPDATE_GROUPED_FIELD = 'UPDATE_GROUPED_FIELD';
export const SET_ALL_DOCUMENTS_STATUS = 'SET_ALL_DOCUMENTS_STATUS';
export const SET_AUTOFILL_PII_MONDAY_ITEM = 'SET_AUTOFILL_PII_MONDAY_ITEM';
export const SET_USER_SMS_CONSENT = 'SET_USER_SMS_CONSENT';
export const SET_SHOW_SMS_VALIDAION_MODAL = 'SET_SHOW_SMS_VALIDAION_MODAL';
export const SET_SMS_VALIDAION_CODE_ERROR = 'SET_SMS_VALIDAION_CODE_ERROR';
export const SET_DOCUMENT_UUID = 'SET_DOCUMENT_UUID';

// Public templates
export const GET_PUBLIC_TEMPLATE = 'GET_PUBLIC_TEMPLATE';
export const SET_PUBLIC_TEMPLATE = 'SET_PUBLIC_TEMPLATE';
export const CREATE_TEMPLATE_PUBLIC_LINK = 'CREATE_TEMPLATE_PUBLIC_LINK';
export const SET_TEMPLATE_PUBLIC_LINK = 'SET_TEMPLATE_PUBLIC_LINK';
export const DELETE_TEMPLATE_LINK_FROM_STORE = 'DELETE_TEMPLATE_LINK_FROM_STORE';

// Public documents
export const GET_PUBLIC_DOCUMENT = 'GET_PUBLIC_DOCUMENT';
export const SET_PUBLIC_DOCUMENT = 'SET_PUBLIC_DOCUMENT';
export const CREATE_DOCUMENT_PUBLIC_LINK = 'CREATE_DOCUMENT_PUBLIC_LINK';
export const SET_DOCUMENT_PUBLIC_LINK = 'SET_DOCUMENT_PUBLIC_LINK';
export const DELETE_DOCUMENT_LINK_FROM_STORE = 'DELETE_DOCUMENT_LINK_FROM_STORE';
export const SAVE_PUBLIC_DOCUMENT = 'SAVE_PUBLIC_DOCUMENT';
export const SET_CREATED_DOCUMENT_FROM_PUBLIC = 'SET_CREATED_DOCUMENT_FROM_PUBLIC';

// Public multi template
export const CREATE_MULTI_TEMPLATE_PUBLIC_LINK = 'CREATE_MULTI_TEMPLATE_PUBLIC_LINK';
export const SET_MULTI_TEMPLATE_PUBLIC_LINK = 'SET_MULTI_TEMPLATE_PUBLIC_LINK';
export const GET_PUBLIC_MULTI_TEMPLATE = 'GET_PUBLIC_MULTI_TEMPLATE';
export const SET_PUBLIC_MULTI_TEMPLATE = 'SET_PUBLIC_MULTI_TEMPLATE';
export const DELETE_MULTI_TEMPLATE_LINK_FROM_STORE = 'DELETE_MULTI_TEMPLATE_LINK_FROM_STORE';
export const SAVE_PUBLIC_MULTI_TEMPLATE = 'SAVE_PUBLIC_MULTI_TEMPLATE';

// Public Form Builder
export const UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD = 'UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD';

// Editor
export const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD';
export const SET_SELECTED_SECTION = 'SET_SELECTED_SECTION';
export const SET_SELECT_FIELD_ERROR = 'SET_SELECT_FIELD_ERROR';
export const REMOVE_SELECT_FIELD_ERROR = 'REMOVE_SELECT_FIELD_ERROR';
export const REMOVE_SELECT_FIELD_ERRORS = 'REMOVE_SELECT_FIELD_ERRORS';
export const SET_PUBLIC_FORM_VALIDATION = 'SET_PUBLIC_FORM_VALIDATION';
export const SET_ACTIVE_EDITOR_TAB = 'SET_ACTIVE_EDITOR_TAB';
export const SET_PUBLIC_FIELD_VALIDATION = 'SET_PUBLIC_FIELD_VALIDATION';
export const CHANGE_FIELD_IN_FOCUS_STATUS = 'CHANGE_FIELD_IN_FOCUS_STATUS';
export const SET_SELECTED_TABLE_QUESTION = 'SET_SELECTED_TABLE_QUESTION';
export const SET_SELECTED_ASSIGNMENT = 'SET_SELECTED_ASSIGNMENT';

// Assignments
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
export const ADD_CUSTOM_ASSIGNMENT = 'ADD_CUSTOM_ASSIGNMENT';
export const REMOVE_ASSIGNMENT = 'REMOVE_ASSIGNMENT';
export const SET_ALL_ASSIGNMENTS = 'SET_ALL_ASSIGNMENTS';
export const CLEAN_ASSIGNMENTS = 'CLEAN_ASSIGNMENTS';
export const ADD_FIELD_TO_ASSIGNMENTS = 'ADD_FIELD_TO_ASSIGNMENTS';
export const SET_ACTIVE_HOVERED_SECTION_KEY = 'SET_ACTIVE_HOVERED_SECTION_KEY';
export const SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL = 'SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL';
export const CHANGE_ASSIGNMENT_LABEL = 'CHANGE_ASSIGNMENT_LABEL';
export const CHANGE_ASSIGNMENT_TYPE = 'CHANGE_ASSIGNMENT_TYPE';
export const SET_ASSIGNMENT_DELETION_INFO = 'SET_ASSIGNMENT_DELETION_INFO';

// Templates aka Documents
export const CREATE_TEMPLATE_SECTION = 'CREATE_TEMPLATE_SECTION';
export const UPDATE_TEMPLATE_SECTION_NAME = 'UPDATE_TEMPLATE_SECTION_NAME';
export const DELETE_TEMPLATE_SECTION = 'DELETE_TEMPLATE_SECTION';
export const UPDATE_TEMPLATE_SECTIONS = 'UPDATE_TEMPLATE_SECTIONS';
export const UPDATE_TEMPLATE_SECTION_DESCRIPTION = 'UPDATE_TEMPLATE_SECTION_DESCRIPTION';
export const SORT_FORM_DOCUMENT_SECTION = 'SORT_FORM_DOCUMENT_SECTION';
export const CLEAR_SECTIONS = 'CLEAR_SECTIONS';

// Multi Templates aka Collections
export const GET_MULTI_TEMPLATE_DETAILS = 'GET_MULTI_TEMPLATE_DETAILS';
export const SET_MULTI_TEMPLATE_DETAILS = 'SET_MULTI_TEMPLATE_DETAILS';
export const CREATE_MULTI_TEMPLATE = 'CREATE_MULTI_TEMPLATE';
export const UPDATE_MULTI_TEMPLATE = 'UPDATE_MULTI_TEMPLATE';

export const GET_MULTI_TEMPLATES_START = 'GET_MULTI_TEMPLATES_START';
export const GET_MULTI_TEMPLATES_SUCCESS = 'GET_MULTI_TEMPLATES_SUCCESS';
export const DELETE_MULTI_TEMPLATES_START = 'DELETE_MULTI_TEMPLATES_START';
export const DELETE_MULTI_TEMPLATES_SUCCESS = 'DELETE_MULTI_TEMPLATES_SUCCESS';
export const SET_PDF_PART_MULTI_TEMPLATE = 'SET_PDF_PART_MULTI_TEMPLATE';
export const SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE = 'SET_PDF_FIELD_BY_IDS_MULTI_TEMPLATE';
export const DELETE_PDF_PARTS_MULTI_TEMPLATE = 'DELETE_PDF_PARTS_MULTI_TEMPLATE';
export const CHANGE_TEMPLATE_IN_COLLECTION = 'CHANGE_TEMPLATE_IN_COLLECTION';

// Collections Executed
export const GET_COLLECTION_EXECUTED_DETAILS = 'GET_COLLECTION_EXECUTED_DETAILS';
export const SET_COLLECTION_EXECUTED_DETAILS = 'SET_COLLECTION_EXECUTED_DETAILS';
export const UPDATE_COLLECTION_EXECUTED = 'UPDATE_COLLECTION_EXECUTED';
export const CREATE_COLLECTION_EXECUTED_PUBLIC_LINK = 'CREATE_COLLECTION_EXECUTED_PUBLIC_LINK';
export const SET_COLLECTION_EXECUTED_PUBLIC_LINK = 'SET_COLLECTION_EXECUTED_PUBLIC_LINK';
export const SET_ALL_COLLECTIONS_EXECUTED = 'SET_ALL_COLLECTIONS_EXECUTED';

// PDF file links
export const SET_PDF_FILE_LINK = 'SET_PDF_FILE_LINK';
export const DELETE_PDF_FILE_LINK = 'DELETE_PDF_FILE_LINK';

// PDF template file link
export const SET_PDF_TEMPLATE_FILE_LINK = 'SET_PDF_TEMPLATE_FILE_LINK';
export const SET_PDF_TEMPLATE_FILE_KEY = 'SET_PDF_TEMPLATE_FILE_KEY';
export const SET_SELECTED_FIELD_KEY = 'SET_SELECTED_FIELD_KEY';
export const UPDATE_FIELD_BY_KEY = 'UPDATE_FIELD_BY_KEY';
export const DELETE_PDF_FIELD = 'DELETE_PDF_FIELD';
export const SET_ALL_PDF_FIELDS_AND_INFO = 'SET_ALL_PDF_FIELDS_AND_INFO';
export const CLEAR_PDF_TEMPLATE_DATA = 'CLEAR_PDF_TEMPLATE_DATA';
export const SET_PDF_TEMPLATE_INFO = 'SET_PDF_TEMPLATE_INFO';
export const SET_PDF_TEMPLATE_WIDTH = 'SET_PDF_TEMPLATE_WIDTH';
export const SET_PDF_FORM_FIELDS = 'SET_PDF_FORM_FIELDS';
export const SET_ALL_PDF_FIELDS = 'SET_ALL_PDF_FIELDS';
export const SET_ALL_PDF_TEMPLATE_INFO = 'SET_ALL_PDF_TEMPLATE_INFO';

// Validation
export const SET_IS_SUBMIT_BUTTON = 'SET_IS_SUBMIT_BUTTON';

// Send Documents
export const SET_SEND_DOCUMENTS = 'SET_SEND_DOCUMENTS';
export const SET_SIGNERS = 'SET_SIGNERS';
export const SET_UPDATED_SIGNERS = 'SET_UPDATED_SIGNERS';
export const UPDATE_SIGNERS_ACCORDING_TO_FIELDS = 'UPDATE_SIGNERS_ACCORDING_TO_FIELDS';
export const SET_EMAIL_INFO = 'SET_EMAIL_INFO';
export const SEND_DOCUMENTS = 'SEND_DOCUMENTS';
export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_ONLY_COLLECTION = 'SET_ONLY_COLLECTION';
export const SET_EXECUTED_DOC_ID = 'SET_EXECUTED_DOC_ID';
export const SET_LINK_VIEW_MODE = 'SET_LINK_VIEW_MODE';
export const SET_CARBON_COPY_USERS_INFO = 'SET_CARBON_COPY_USERS';
export const ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH = 'ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH';
export const SET_SIGNER_FLOW = 'SET_SIGNER_FLOW';

// Form Builder
export const UPDATE_FORM_BUILDER = 'UPDATE_FORM_BUILDER';
export const ADD_SECTIONS_TO_FORM_BUILDER = 'ADD_SECTIONS_TO_FORM_BUILDER';
export const REMOVE_SECTIONS_FROM_FORM_BUILDER = 'REMOVE_SECTIONS_FROM_FORM_BUILDER';
export const UPDATE_FORM_BUILDER_SECTIONS_ORDER = 'UPDATE_FORM_BUILDER_SECTIONS_ORDER';
export const ADD_FIELD_TO_FORM_BUILDER = 'ADD_FIELD_TO_FORM_BUILDER';
export const UPDATE_PARAGRAPH_VALUE = 'UPDATE_PARAGRAPH_VALUE';
export const UPDATE_FORM_BUILDER_SECTIONS = 'UPDATE_FORM_BUILDER_SECTIONS';
export const SET_FORM_BUILDER_SELECTED_FIELDS_KEYS = 'SET_FORM_BUILDER_SELECTED_FIELDS_KEYS';
export const CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS = 'CLEAR_FORM_BUILDER_SELECTED_FIELDS_KEYS';
export const SET_IS_DRAG_START_STATUS = 'SET_IS_DRAG_START_STATUS';
export const SET_IS_DRAG_STOP_STATUS = 'SET_IS_DRAG_STOP_STATUS';
export const SET_OUTLINE_ACTIVE_SECTION_KEY = 'SET_OUTLINE_ACTIVE_SECTION_KEY';
export const CLEAR_OUTLINE_ACTIVE_SECTION_KEY = 'CLEAR_OUTLINE_ACTIVE_SECTION_KEY';
export const SET_FORM_BUILDER_GROUPED_FIELDS_KEYS = 'SET_FORM_BUILDER_GROUPED_FIELDS_KEYS';

// Company Branding
export const GET_COMPANY_BRANDING = 'GET_COMPANY_BRANDING';
export const SET_COMPANY_BRANDING = 'SET_COMPANY_BRANDING';
export const UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const DELETE_COMPANY_LOGO = 'DELETE_COMPANY_LOGO';
export const UPDATE_COMPANY_STYLE_SCHEMA = 'UPDATE_COMPANY_STYLE_SCHEMA';
export const SET_COMPANY_STYLE_SCHEMA = 'SET_COMPANY_STYLE_SCHEMA';
export const SET_CUSTOM_DOMAIN = 'SET_CUSTOM_DOMAIN';
export const SET_CUSTOM_DOMAIN_CREATION_DATE = 'SET_CUSTOM_DOMAIN_CREATION_DATE';
export const DELETE_CUSTOM_DOMAIN = 'DELETE_CUSTOM_DOMAIN';

// Email notifications
export const UPDATE_SENDGRID_SETTINGS = 'UPDATE_SENDGRID_SETTINGS';
export const SET_SENDGRID_SETTINGS = 'SET_SENDGRID_SETTINGS';

// Storage
export const SET_PERSISTENT_STORAGE_FIELD_DATA = 'SET_PERSISTENT_STORAGE_FIELD_DATA';
export const DELETE_PERSISTENT_STORAGE_DATA_BY_KEY = 'DELETE_PERSISTENT_STORAGE_DATA_BY_KEY';
export const RESTORE_DATA_FROM_PERSISTENT_STORAGE = 'RESTORE_DATA_FROM_PERSISTENT_STORAGE';
export const SET_RESTORE_DATA_MODAL_VISIBILITY = 'SET_RESTORE_DATA_MODAL_VISIBILITY';

// Page Settings
export const SET_PAGE_SETTING_VALUE_BY_KEY = 'SET_PAGE_SETTING_VALUE_BY_KEY';
export const SET_PAGE_SETTING_VALUE_FOR_TEMPLATE = 'SET_PAGE_SETTING_VALUE_FOR_TEMPLATE';
export const SET_PAGE_SETTING_VALUE_FOR_DOCUMENT = 'SET_PAGE_SETTING_VALUE_FOR_DOCUMENT';
export const SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT = 'SET_PAGE_SETTING_VALUE_FOR_NEW_DOCUMENT';
export const CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT = 'CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT';

// Payment: Stripe
export const SET_ACTIVE_STRIPE_PRODUCT_ID = 'SET_ACTIVE_STRIPE_PRODUCT_ID';
export const SET_ACTIVE_STRIPE_SUBSCRIPTION = 'SET_ACTIVE_STRIPE_SUBSCRIPTION';

// Integration: Monday.com
export const SET_MONDAY_COM_ACCESS_TOKEN = 'SET_MONDAY_COM_ACCESS_TOKEN';
export const SAVE_MONDAY_COM_ACCESS_TOKEN = 'SAVE_MONDAY_COM_ACCESS_TOKEN';
export const DELETE_MONDAY_COM_ACCESS_TOKEN = 'DELETE_MONDAY_COM_ACCESS_TOKEN';
export const SET_MONDAY_COM_BOARDS = 'SET_MONDAY_COM_BOARDS';
export const GET_MONDAY_COM_BOARDS = 'GET_MONDAY_COM_BOARDS';
export const SAVE_MONDAY_COM_INTEGRATION_SETTINGS = 'SAVE_MONDAY_COM_INTEGRATION_SETTINGS';
export const SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT = 'SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_DOCUMENT';
export const SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION = 'SET_MONDAY_COM_INTEGRATION_SETTINGS_INTO_COLLECTION';
export const SET_FIELD_TYPE_CHECKER_MODAL_OPEN = 'SET_FIELD_TYPE_CHECKER_MODAL_OPEN';
export const SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING = 'SET_COLUMN_NAME_AND_FIELD_TO_UPDATE_MAPPING';
export const LAUNCH_UPDATE_FIELDS_MAPPING = 'LAUNCH_UPDATE_FIELDS_MAPPING';
export const CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS = 'CHECK_IF_USER_ON_PUBLIC_PAGE_HAS_MONDAYCOM_RECORDS';
export const SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW = 'SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW';
export const SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW = 'SEND_EMAIL_IN_MONDAY_COM_MULTI_APPLICATIONS_FLOW';
export const SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM = 'SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM';
export const SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE = 'SET_WAIT_TO_PASSWORDLESS_LINK_RESPONSE';
export const SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION = 'SET_OPEN_POPUP_BECAUSE_OF_SMS_VALIDATION';

// SignNow
export const SET_SIGN_NOW_FILE_URL = 'SET_SIGN_NOW_FILE_URL';
export const SET_SIGN_NOW_PDF_FORM_FIELDS = 'SET_SIGN_NOW_PDF_FORM_FIELDS';
export const REMOVE_SIGN_NOW_FILE = 'REMOVE_SIGN_NOW_FILE';
export const CLEAR_SIGN_NOW_FILE_FROM_STORE = 'CLEAR_SIGN_NOW_FILE_FROM_STORE';
export const SET_CURRENT_SIGNER_FLOW_TO_STORE = 'SET_CURRENT_SIGNER_FLOW_TO_STORE';
export const SET_SIGNERS_LIST = 'SET_SIGNERS_LIST';
export const SET_CURRENT_SIGNER = 'SET_CURRENT_SIGNER';
export const SET_IS_SIGN_NOW_ACTIVE = 'SET_IS_SIGN_NOW_ACTIVE';
export const CLEAR_SIGN_NOW_STORE = 'CLEAR_SIGN_NOW_STORE';
export const CLEAR_FULL_SIGN_NOW_STORE = 'CLEAR_FULL_SIGN_NOW_STORE';
export const SET_IS_REMIND_DOCUMENT_ACTIVE = 'SET_IS_REMIND_DOCUMENT_ACTIVE';
export const SET_IS_REMIND_FOR_ALL_SIGNERS = 'SET_IS_REMIND_FOR_ALL_SIGNERS';
export const SET_SIGN_NOW_DOCUMENT_INFO = 'SET_SIGN_NOW_DOCUMENT_INFO';
export const UPDATE_SIGN_NOW_DOCUMENT = 'SET_SIGN_NOW_DOCUMENT_ID';
export const CREATE_COLLECTION_IN_SIGN_NOW_FLOW = 'CREATE_COLLECTION_IN_SIGN_NOW_FLOW';
export const SET_IS_COLLECTION_IN_SIGN_NOW = 'SET_IS_COLLECTION_IN_SIGN_NOW';
export const SET_COLLECTION_ID_IN_SIGN_NOW = 'SET_COLLECTION_ID_IN_SIGN_NOW';
export const SET_PDF_TEMPLATE_ID_IN_SIGN_NOW = 'SET_PDF_TEMPLATE_ID_IN_SIGN_NOW';

// Server Side Events
export const SSE_UPDATE_RESOURCE = 'SSE_UPDATE_RESOURCE';
export const SSE_UPDATE_RESOURCES_BY_TYPE = 'SSE_UPDATE_RESOURCES_BY_TYPE';

// SMS validation
export const SEND_UNIQUE_CODE_TO_USER = 'SEND_UNIQUE_CODE_TO_USER';
export const SEND_CODE_FOR_VERIFICATION = 'SEND_CODE_FOR_VERIFICATION';

// MuiTableState
export const SET_SELECTED_TABLE_ROW = 'SET_SELECTED_TABLE_ROW';