import { CHUNK_SIZE } from 'constants/general';
import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import {
  ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE,
  CLEAR_ATTACHMENTS,
  DELETE_CURRENT_ID_FROM_DATA,
  DELETE_DOCUMENT_LINK_FROM_STORE,
  DELETE_MULTI_TEMPLATE_LINK_FROM_STORE,
  DELETE_PART_OF_HISTORY_LOG,
  DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE,
  DELETE_TEMPLATE_LINK_FROM_STORE,
  SET_ALL_DOCUMENTS_STATUS,
  SET_ATTACHMENTS_QUEUE_COMPLETED,
  SET_AUTOFILL_PII_DATA,
  SET_AUTOFILL_PII_MONDAY_ITEM,
  SET_COLLECTION_EXECUTED_PUBLIC_LINK,
  SET_CREATED_DOCUMENT_FROM_PUBLIC,
  SET_CURRENT_DOCUMENT_TAB,
  SET_DISMISS_INTRODUCE_STEP,
  SET_DOCUMENT_PUBLIC_LINK,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENT_UUID,
  SET_GROUPED_FIELDS_STRUCTURE,
  SET_HISTORY_LOG_CURRENT_DOCUMENT,
  SET_MONDAY_COM_BOARD,
  SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM,
  SET_MULTI_TEMPLATE_PUBLIC_LINK,
  SET_PII_DATA_MODAL_WAS_VISIBLE,
  SET_PUBLIC_DOCUMENT,
  SET_PUBLIC_MULTI_TEMPLATE,
  SET_PUBLIC_PAGE_CURRENT_DOCUMENT,
  SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX,
  SET_PUBLIC_PAGE_DATA,
  SET_PUBLIC_PAGE_SENT_FILE_COUNTER,
  SET_PUBLIC_PAGE_START_LINK,
  SET_PUBLIC_PAGE_STRUCTURE,
  SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES,
  SET_PUBLIC_TEMPLATE,
  SET_SEND_LINK_FLOW,
  SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW,
  SET_SHOW_SMS_VALIDAION_MODAL,
  SET_SMS_VALIDAION_CODE_ERROR,
  SET_TEMPLATE_PUBLIC_LINK,
  SET_USER_SMS_CONSENT,
  SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW,
  UPDATE_GROUPED_FIELD,
  UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES,
  UPDATE_PUBLIC_PAGE_DATA,
  UPDATE_PUBLIC_PAGE_DOCUMENTS,
  UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD,
  UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD,
  UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD,
} from 'store/actions/actionTypes';
import { INewSection } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { IPublicPageState } from 'types/PublicPage';
import { Action } from 'types/redux';
import { IDocumentSection } from 'types/Sections';
import { ITemplateDetails } from 'types/Templates';
import { isPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import updatePublicPageDocuments from 'utils/PublicPage/updatePublicPageDocuments';
import {
  updatePdfAttachmentProperties,
  updatePublicPageDataAttachmentField,
  updatePublicPageIntroCurrentAttachmentFieldHelper,
  updatePublicPageIntroDocumentsAttachmentField,
  updateSectionAttachmentFieldProperties,
} from 'utils/PublicPage/updatePublicPageStoreHelper';
import { isDocumentType, isTemplateType } from 'utils/typeChecker';

const initialState = {
  currentDocument: null,
  data: null,
  structure: {
    main: {
      id: '',
      pageType: '',
    },
    documents: [],
    formBuilderFields: {},
    groupedFieldsStructure: [],
  },
  attachments: {},
  totalAttachedFilesCounter: 0,
  sentFilesCounter: 0,
  template: {},
  document: {},
  multiTemplate: {},
  templatePublicLink: '',
  documentPublicLink: '',
  multiTemplatePublicLink: '',
  collectionExecutedPublicLink: '',
  publicViewMode: '',
  isFormDocumentShareLink: false,
  documentId: 0,
  attachmentsQueueCompleted: 0,
  documentType: null, // Used for attachments on Public and some Manager editor pages
  isSendLinkFlow: false,
  dismissIntroduceStep: false,
  autoFillPIIData: true,
  startLink: '',
  showRecallPIIDataModalWindow: false,
  showMondayComSimilarRecordsExistModalWindow: false,
  mondayComBoard: undefined,
  mondayComItemIdToFilloutSubitem: undefined,
  wasPIIDataModalWindowVisible: false,
  autofillPIIMondayItem: null,
  userSmsConsent: false,
  smsValidationModalState: null,
  smsValidationError: '',
  documentUUID: null,
};

// eslint-disable-next-line default-param-last
export default (state: IPublicPageState = initialState, action: Action) => {
  switch (action.type) {
    case SET_PUBLIC_PAGE_DATA:
    case SET_PUBLIC_PAGE_STRUCTURE:
    case SET_PUBLIC_PAGE_START_LINK:
    case SET_AUTOFILL_PII_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PII_DATA_MODAL_WAS_VISIBLE:
      return {
        ...state,
        wasPIIDataModalWindowVisible: true,
      };
    case DELETE_CURRENT_ID_FROM_DATA:
      return {
        ...state,
        data: { ...state.data, id: null },
      };
    case UPDATE_PUBLIC_PAGE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_PUBLIC_PAGE_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload.id
          ? {
            ...state.currentDocument,
            ...state.structure.documents.find((document) => Number(action.payload.id) === Number(document.id)),
          }
          : null,
      };
    case UPDATE_PUBLIC_PAGE_DOCUMENTS:
      return {
        ...state,
        structure: action.payload,
      };
    case SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX:
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          currentSlideIndex: action.payload,
        },
      };
    case SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES:
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          totalIndexSlides: action.payload,
        },
      };
    case SET_CURRENT_DOCUMENT_TAB:
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          activeEditorTab: action.payload,
        },
      };
    case ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE:
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.payload.fieldID]: [
            ...Object.values(state.attachments[action.payload.fieldID] || []),
            action.payload.file,
          ],
        },
        totalAttachedFilesCounter: action.payload.file.status !== 'error'
          ? state.totalAttachedFilesCounter + Math.floor(action.payload.file.size / CHUNK_SIZE) + 1
          : state.totalAttachedFilesCounter,
      };
    case DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE:
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.payload.fieldID]: state.attachments[action.payload.fieldID].filter((file) => (
            file.upload.uuid !== action.payload.file.upload.uuid
          )),
        },
        totalAttachedFilesCounter: action.payload.file.status !== 'error'
          ? state.totalAttachedFilesCounter - Math.floor(action.payload.file.size / CHUNK_SIZE) - 1
          : state.totalAttachedFilesCounter,
      };
    case UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD:
      return {
        ...state,
        structure: {
          ...state.structure,
          documents: updatePublicPageIntroDocumentsAttachmentField(state, action),
        },
        data: updatePublicPageDataAttachmentField(state, action),
      };
    case UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD:
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          attachmentFields: updatePublicPageIntroCurrentAttachmentFieldHelper(state, action),
        },
      };
    case SET_PUBLIC_PAGE_SENT_FILE_COUNTER:
      return {
        ...state,
        sentFilesCounter: state.sentFilesCounter + 1,
      };
    case SET_PUBLIC_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case SET_TEMPLATE_PUBLIC_LINK:
      return {
        ...state,
        templatePublicLink: action.payload.result,
        publicViewMode: action.payload.mode,
        isFormDocumentShareLink: action.payload.is_form_document,
      };
    case DELETE_TEMPLATE_LINK_FROM_STORE:
      return {
        ...state,
        templatePublicLink: '',
        publicViewMode: '',
        isFormDocumentShareLink: false,
      };
    case SET_PUBLIC_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case SET_DOCUMENT_PUBLIC_LINK:
      return {
        ...state,
        documentPublicLink: action.payload.result,
        publicViewMode: action.payload.mode,
      };
    case DELETE_DOCUMENT_LINK_FROM_STORE:
      return {
        ...state,
        documentPublicLink: '',
        publicViewMode: '',
        isFormDocumentShareLink: false,
      };
    case SET_CREATED_DOCUMENT_FROM_PUBLIC:
      return {
        ...state,
        documentId: action.payload,
      };
    case SET_PUBLIC_MULTI_TEMPLATE:
      return {
        ...state,
        multiTemplate: action.payload,
      };
    case SET_MULTI_TEMPLATE_PUBLIC_LINK:
      return {
        ...state,
        multiTemplatePublicLink: action.payload.result,
        publicViewMode: action.payload.mode,
      };
    case SET_COLLECTION_EXECUTED_PUBLIC_LINK:
      return {
        ...state,
        collectionExecutedPublicLink: action.payload.result,
        publicViewMode: action.payload.mode,
      };
    case DELETE_MULTI_TEMPLATE_LINK_FROM_STORE:
      return {
        ...state,
        multiTemplatePublicLink: '',
        publicViewMode: '',
      };
    case UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES:
      if (isDocumentType(state.data)) {
        return {
          ...state,
          data: updatePublicPageDataAttachmentField(state, action),
        };
      }

      if (isTemplateType(state.data)) {
        const isPDFFlow: boolean = isPDFDocument(state.data);
        let updatedData: Partial<INewSection>[] | Partial<PDFFieldType> = [];

        if (!isPDFFlow) {
          updatedData = (state.data as ITemplateDetails).sections?.map((sectionInfo: IDocumentSection) => ({
            ...sectionInfo,
            section: {
              ...sectionInfo.section,
              content_json: updateSectionAttachmentFieldProperties(sectionInfo.section.content_json, action),
            },
          }));
        } else {
          updatedData = updatePdfAttachmentProperties(state, action) as PDFFieldType;
        }

        const updatedAttachmentFields = !isPDFFlow
          ? { sections: updatedData }
          : { pdf_fields_json: updatedData };

        return {
          ...state,
          data: {
            ...state.data,
            ...updatedAttachmentFields,
          },
        };
      }
      return state;
    case SET_HISTORY_LOG_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          historyLog: {
            ...state.currentDocument?.historyLog,
            [action.payload.target]: action.payload.value,
          },
        },
      };
    case DELETE_PART_OF_HISTORY_LOG: {
      const historyObject = { ...state.currentDocument?.historyLog };
      if (historyObject) {
        delete historyObject[action.payload];
      }
      return {
        ...state,
        currentDocument: {
          ...state.currentDocument,
          historyLog: historyObject,
        },
      };
    }
    case CLEAR_ATTACHMENTS:
      return {
        ...state,
        totalAttachedFilesCounter: 0,
        sentFilesCounter: 0,
        attachments: {},
        attachmentsQueueCompleted: 0,
      };
    case SET_ATTACHMENTS_QUEUE_COMPLETED:
      return {
        ...state,
        attachmentsQueueCompleted: state.attachmentsQueueCompleted + 1,
      };
    case SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };
    case UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD:
      return {
        ...state,
        structure: {
          ...state.structure,
          formBuilderFields: {
            ...state.structure.formBuilderFields,
            ...Object.fromEntries(action.payload.keys.map((key: number) => ([
              key,
              {
                ...state.structure.formBuilderFields[key],
                ...action.payload.value,
                ...action.payload.additionalParameter,
              },
            ]))),
          },
          documents: state.structure.documents.map((document) => (
            updatePublicPageDocuments({
              document,
              payload: action.payload,
            })
          )),
        },
        currentDocument: updatePublicPageDocuments({
          document: state.currentDocument,
          payload: action.payload,
        }),
      };
    case SET_SEND_LINK_FLOW:
      return {
        ...state,
        isSendLinkFlow: action.payload,
      };
    case SET_DISMISS_INTRODUCE_STEP:
      return {
        ...state,
        dismissIntroduceStep: action.payload,
      };
    case SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW:
      return {
        ...state,
        showRecallPIIDataModalWindow: action.payload,
      };
    case SHOW_MONDAY_COM_SIMILAR_RECORDS_EXIST_MODAL_WINDOW:
      return {
        ...state,
        showMondayComSimilarRecordsExistModalWindow: action.payload,
      };
    case SET_MONDAY_COM_BOARD:
      return {
        ...state,
        mondayComBoard: action.payload,
      };
    case SET_MONDAY_COM_ITEM_ID_TO_FILLOUT_SUBITEM:
      return {
        ...state,
        mondayComItemIdToFilloutSubitem: action.payload,
      };
    case SET_AUTOFILL_PII_MONDAY_ITEM:
      return {
        ...state,
        autofillPIIMondayItem: action.payload,
      };
    case SET_GROUPED_FIELDS_STRUCTURE:
      return {
        ...state,
        structure: {
          ...state.structure,
          groupedFieldsStructure: action.payload,
        },
      };
    case UPDATE_GROUPED_FIELD:
      return {
        ...state,
        structure: {
          ...state.structure,
          groupedFieldsStructure: state.structure.groupedFieldsStructure.map((field) => {
            if (field.keys.includes(action.payload.key)) {
              return {
                ...field,
                value: action.payload.value,
              };
            }
            return field;
          }),
        },
      };
    case SET_ALL_DOCUMENTS_STATUS:
      return {
        ...state,
        structure: {
          ...state.structure,
          documents: state.structure.documents.map((document) => ({
            ...document,
            status: PUBLIC_PAGE_STATUS.NOT_OPEN,
          })),
        },
      };
    case SET_USER_SMS_CONSENT:
      return {
        ...state,
        userSmsConsent: action.payload,
      };
    case SET_SHOW_SMS_VALIDAION_MODAL:
      return {
        ...state,
        smsValidationModalState: {
          showSMSValidationModal: action.payload.showModal,
          userPhoneNumber: action.payload.phoneNumber,
          sendSMSUniqueCode: action.payload.sendSMSUniqueCode,
          phoneNumbersDontMatch: action.payload.phoneNumbersDontMatch,
        },
      };
    case SET_SMS_VALIDAION_CODE_ERROR:
      return {
        ...state,
        smsValidationError: action.payload,
      };
    case SET_DOCUMENT_UUID:
      return {
        ...state,
        documentUUID: action.payload,
      };
    default:
      return state;
  }
};