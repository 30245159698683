import { FC } from 'react';

import { Link } from '@mui/material';

import Button from 'components/Base/Button';
import Intro from 'components/Intro/Intro';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import DigitInputBox from 'components/Modals/SMSValidation/DigitInputBox';
import useVerifyUniqueCode, { SMS_CODE_LENGHT } from 'hooks/SMSValidation/useVerifyUniqueCode';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import ArrowRightIcon from 'svg/ArrowIcons/ArrowRightIcon';

import 'scss/components/TabNavigation/TabNavigation.scss';
import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

const VerifyUniqueCodeModal: FC = () => {
  const {
    setReferences,
    localCode,
    smsValidationError,
    changeCodeBoxHandler,
    showSMSValidationModal,
    userPhoneNumber,
    sendSMSUniqueCode,
    phoneNumbersDontMatch,
    applyUniqueCodeHandler,
    sendCodeAgainHandler,
    sendEmailWithPasswordlessLink,
    applyRecallPIIDataFlow,
  } = useVerifyUniqueCode();
  const styleSchema = useStyleSchema<CustomStylesEnum.button>([CustomStylesEnum.button]);

  const generateDescription = () => {
    if (phoneNumbersDontMatch) {
      return (<>Phone numbers don&apos;t match. Please, reload the page and try again.</>);
    }
    if (sendSMSUniqueCode) {
      return (
        <>For your security, an SMS code has been sent to your phone ending in {userPhoneNumber?.substring(userPhoneNumber.length - 4)}</>
      );
    }
    return (
      <>
        The document sender has requested additional identity verification.
        We have sent a code to a cell phone ending in {userPhoneNumber?.substring(userPhoneNumber.length - 4)}
      </>
    );
  };

  return (
    <ModalContentWindow
      centered
      showDefaultText={false}
      showModal={showSMSValidationModal}
      onCloseModal={() => null}
      titleText=""
      showCloseButton={false}
    >
      <Intro
        title="Validate Your Identity"
        description={generateDescription()}
        descriptionWidth="large"
        justifyContent="center"
      >
        {
          !phoneNumbersDontMatch
            ? (
              <>
                <div className="d-flex my-3">
                  {
                    Array.from({ length: SMS_CODE_LENGHT }).map((_, index) => (
                      <DigitInputBox
                        setReferences={setReferences}
                        key={`SmsCodeBox${index + 1}`}
                        index={index}
                        value={localCode[index]}
                        changeCodeBoxHandler={changeCodeBoxHandler}
                      />
                    ))
                  }
                </div>
                {
                  smsValidationError && (
                    <span className="invalid-field mb-2">{smsValidationError}</span>
                  )
                }
                <Link component="button" onClick={sendCodeAgainHandler}>Send Code Again</Link>
                {
                  !sendSMSUniqueCode && (
                    <Link component="button" onClick={sendEmailWithPasswordlessLink}>
                      {
                        applyRecallPIIDataFlow
                          ? 'Check remembered data and send email if it exists'
                          : 'Send an Email'
                      }
                    </Link>
                  )
                }
                <div className="d-flex justify-content-center w-100 mt-4">
                  <Button
                    endIcon={<ArrowRightIcon />}
                    onClick={applyUniqueCodeHandler}
                    classes={classes.PublicButton}
                    style={styleSchema.button}
                  >
                    Start Signing
                  </Button>
                </div>
              </>
            )
            : null
        }
      </Intro>
    </ModalContentWindow>
  );
};

export default VerifyUniqueCodeModal;