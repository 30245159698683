import { FC } from 'react';

import {
  CUSTOM_DOMAIN_EXPLANATION_TEXT,
  CUSTOM_DOMAIN_HELP_TEXT,
} from 'constants/accountSettings';

const CustomDomainHelpText: FC<{ subdomain: string | null }> = ({
  subdomain,
}) => (
  <>
    <span className="d-block mt-3">{CUSTOM_DOMAIN_HELP_TEXT}</span>
    <span className="d-block mt-3 fw-bold">Read First!</span>
    <span className="d-block mt-3">{CUSTOM_DOMAIN_EXPLANATION_TEXT}</span>
    <table className="table mt-3">
      <thead>
        <tr>
          <th scope="col">Type</th>
          <th scope="col">Name</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>CNAME</td>
          <td>{subdomain ?? ''}</td>
          <td>app.unicornforms.com</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default CustomDomainHelpText;